import * as React from "react"
import Layout from "../layouts/default"
import { useQueryParam, StringParam } from "use-query-params";


// styles
const centerCenter = {
  display: "flex",
  flexDirection: "vertical",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh"
}

export default function Home() {
  const [podcastId] = useQueryParam("id", StringParam);
  console.log(podcastId);
  return (
    <Layout
      title="Home"
      podcastId={podcastId}
    >
      <div style={centerCenter}>
        <center>
          <svg
            id="logo"
            data-name="logo"
            xmlns="http://www.w3.org/2000/svg"
            width="32" height="32"
            viewBox="0 0 500 499.997">
            <path
              id="Path_4"
              data-name="Path 4"
              d="M125,0h375V62.5a125,125,0,0,1-125,125H0V125A125,125,0,0,1,125,0Z"
              transform="translate(0.001 0)"
            />
            <path
              id="Subtraction_2"
              data-name="Subtraction 2"
              d="M374.992,250.006H0V0H374.992a125,125,0,1,1,0,250.006ZM62.5,62.5v125H374.992a62.5,62.5,0,1,0,0-125H62.5Z"
              transform="translate(0 249.992)"
            />
          </svg>
          <h4>Soapbox</h4>
          <h6>Under construction</h6>
        </center>
      </div>
    </Layout>
  )
}